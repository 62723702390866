export default {
    setyj: {
        kongzhitai: '控制台',
        guoneifahuo: '国内大货',
        zxxb: '专线小包',
        kuaididingdan: '快递打单',
        haiwaifahuo: '海外发货',
        caiwuzhongxin: '财务中心',
        syssettings: '系统设置',
        ptdr: '平台管理',

    },
    //二级翻译
    seterj: {
        gnlist: '订单列表',
        gnwaybill: '预报订单',
        gnpilsc: '批量上传',
        ddlist: '订单列表',
        zlist: '订单列表',
        zwaybill: '预报订单',
        zpilsc: '批量上传',
        rcdgl: '入仓总单',
        waybill: '创建运单',
        pilsc: '批量上传',
        hwddlist: '订单列表',
        hwybdd: '预报订单',
        hwplsc: '批量上传',
        xjrk: '库存管理',
        rkjl: '入库记录',
        caiwuls: '财务流水',
        dhzd: '大货账单',
        ddgl: '地址管理',
        qdjg: '渠道价格',
        gdlb: '工单列表',
        xtpz: '系统配置',
        ptgl: '平台管理',
        xbzd: '小包账单',
        dscz: '仓租提醒',
        skugl: 'SKU管理',
        xstj: '销售统计',
        ddxg: '打单相关',
        ptzh: '平台账号',
        skugx: 'SKU关系',
        zcmd: '自传面单',
        hwsf: 'SCANFORM',
        kdsf: 'SCANFORM',
        lsddlb: '临时订单列表',
    },
    // 快递打单-预报订单
    kdyddd: {
        sj_name: '收件姓名',
        sj_tel: '收件电话',
        sj_gs: '收件公司',
        sj_dizhi: '收件地址',
        sj_ycdizhi: '延长地址',
        sj_chengshi: '收件城市',
        sj_zouseng: '收件州省',
        sj_youbian: '收件邮编',
        sj_gj: '目的国家',
        dd_bh: '订单编号',
        sj_zdybz: '自定备注',
        insurance: '投保金额',
        sjxinxi: '收件信息',
        orderSIZE: '订单设置',
        xzsl: '箱子数量',
        unitsize: '单位设置',
        xts: '小提示：',
        xtswenzi: '可以在Excel中编辑好，复制并粘贴到下方表格。',
    },
    // 快递打单-预报订单placeholder
    placeholder: {
        sj_name: '请输入收件姓名',
        sj_tel: '请输入收件电话',
        sj_gs: '请输入收件公司',
        sj_dizhi: '请输入收件地址',
        sj_ycdizhi: '地址过长时使用',
        sj_chengshi: '请输入收件城市',
        sj_zouseng: '请输入收件州省',
        sj_youbian: '请输入收件邮编',
        sj_gj: '请选择国家',
        dd_bh: '为空时自动生成',
        sj_zdybz: '多用于Label上的备注',
        insurance: '为空不投保 (需渠道支持)',
        dizhiku: '保存到收件地址库',
        fbayundan: 'FBA运单',
        xtzlcc: '相同重量尺寸',

    },
    // 快递打单-批量上传
    kdpilsc: {
        pilisngTips: '该功能仅适用于单票单件的订单，一票多件的订单暂时不能批量导入。',
        mobanTips: '模板',
        xzplmbTips: '下载批量模板',
        ddpcTips: '订单批次：',
        zlszTips: '重量设置：',
        scwjTips: '上传文件：',
        ddnumTips: '订单数量：',
        sclistTips: '上传列表',
        xitlistTips: '( 注意:系统仅保留30天的Excel导入记录! )',
        piliangTips: '批量删除',
        pichiTips: '批次',
        fileTips: '文件',
        ordernumTips: '订单数量',
        successnumTips: '成功数量',
        failnumTips: '失败数量',
        uptime1Tips: '上传时间',
        caozuoTips: '操作',
    },
    // 快递打单-批量上传
}