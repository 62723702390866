export default {
    setyj: {
        kongzhitai: 'Console',
        guoneifahuo: 'Domestic Large Cargo',
        zxxb: 'Dedicated parcel',
        kuaididingdan: 'Express order',
        haiwaifahuo: 'Overseas shipments',
        caiwuzhongxin: 'Finance Center',
        syssettings: 'System settings',
        ptdr: 'Platform Management',

    },
    //二级翻译
    seterj: {
        gnlist: 'Order List',
        gnwaybill: 'Forecast order',
        gnpilsc: 'Batches',
        ddlist: 'Shipments',
        zlist: 'Order List',
        zwaybill: 'Forecast order',
        zpilsc: 'Batches',
        rcdgl: 'Warehouse order',
        waybill: 'New Shipment',
        pilsc: 'Batches',
        hwddlist: 'Order List',
        hwplsc: 'Batches',
        hwybdd: 'Forecast order',
        xjrk: 'Inventory management ',
        rkjl: 'Inventory records',
        ddgl: 'Address management',
        caiwuls: 'Ainancial flows',
        dhzd: 'Bulk billing',
        qdjg: 'Channel price',
        gdlb: 'Ticket list',
        xtpz: 'System Configuration',
        ptgl: 'Platform Management',
        xbzd: 'Small packet billing',
        dscz: 'Warehouse Rental Reminder',
        skugl: 'SKU management',
        xstj: 'Sales Statistics',
        ddxg: 'Order related',
        ptzh: 'Platform account',
        skugx: 'SKU relationship',
        zcmd: 'Autobiographical sheet',
        hwsf: 'SCANFORM',
        kdsf: 'SCANFORM',
        lsddlb: 'Temporary Order',
    },
    // 快递打单-预报订单
    kdyddd: {
        sj_name: ' Name',
        sj_tel: ' Phone',
        sj_gs: ' Company',
        sj_dizhi: ' Addres',
        sj_ycdizhi: ' Addres 2',
        sj_chengshi: ' City',
        sj_zouseng: ' State',
        sj_youbian: 'PostCode',
        sj_gj: ' Country',
        dd_bh: 'OrderID',
        sj_zdybz: 'Reference',
        insurance: 'Insured',
        sjxinxi: 'Receiving Information',
        orderSIZE: 'Order Settings',
        xzsl: 'Number of boxes',
        unitsize: 'Unit setting',
        xts: 'Tip:',
        xtswenzi: 'You can edit it in Excel, copy and paste it into the table below.',


    },
    // 快递打单-预报订单placeholder
    placeholder: {
        sj_name: 'Please enter the recipient name',
        sj_tel: 'Please enter the recipient phone number',
        sj_gs: 'Please enter the recipient company',
        sj_dizhi: 'Please enter the shipping address',
        sj_ycdizhi: 'Used when the address is too long',
        sj_chengshi: 'Please enter the recipient city',
        sj_zouseng: 'Please enter the recipient state or province',
        sj_youbian: 'Please enter the recipient postal code',
        sj_gj: 'Please select a country',
        dd_bh: 'Automatically generated when empty',
        sj_zdybz: 'Commonly used for notes on labels',
        insurance: 'Not insured for empty space (requires channel support)',
        dizhiku: 'Save to recipient address library',
        fbayundan: 'FBA waybill',
        xtzlcc: 'Same weight and size',
        xhws: 'Box number digits',




    },
    // 快递打单-批量上传
    kdpilsc: {
        pilisngTips: 'This function is only applicable to single item orders, and orders with multiple items cannot be imported in bulk temporarily.',
        mobanTips: 'template',
        xzplmbTips: 'Download bulk templates',
        ddpcTips: 'Order Batch:',
        zlszTips: 'Weight setting:',
        scwjTips: 'Upload file:',
        ddnumTips: 'Order quantity:',
        sclistTips: 'Upload List',
        xitlistTips: '(Note: The system only retains 30 days of Excel import records!)',
        piliangTips: 'Batch Delete',
        pichiTips: 'batch',
        fileTips: 'file',
        ordernumTips: 'Order Quantity',
        successnumTips: 'Successful quantity',
        failnumTips: 'Number of failures',
        uptime1Tips: 'uploaded',
        caozuoTips: 'operate',
    }
}